<template>
  <ListLayout ref="layout"
              :title="$t('orders.myOrders.pageTitle')"
              :index-request="indexRequest"
              detail-to="my-orders.show"
              :crud-headers="crudHeaders"
              :show-store-filter="false"/>
</template>

<script>
import { storeIndex } from '@/modules/orders/api/order.js';
import ListLayout from '@/modules/orders/components/ListLayout.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MyOrdersList',
  components: { ListLayout },
  watch: {
    async storeId() {
      this.$refs.layout.$refs.table.reload();
    },
  },
  computed: {
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
    crudHeaders() {
      return [
        {
          value: 'statusIcon',
          text: '',
          width: 48,
        },
        {
          value: 'orderStatusId',
          language: 'orderStatusId',
        },
        {
          value: 'storeName',
          language: 'storeName',
        },
        {
          value: 'userName',
          language: 'userName',
        },
        {
          value: 'countryName',
          language: 'countryName',
        },
        {
          value: 'date',
          language: 'date',
        },
      ];
    },
  },
  methods: {
    indexRequest(...args) {
      return storeIndex(this.storeId, ...args);
    },
  },
};
</script>
